<template>
  <section class="jetcbt-url">
    <p>
      JET CBTを受検するには、受検用PCまたはタブレットで、<br>
      ブラウザに次の「URL」を入力し「会場識別コード」を<br>
      入力してください。<br>
      「会場識別コード」は受検期間中のみ有効です。
    </p>
    <div class="mb-3">
      <div><span class="code-label">URL: </span><span class="value-to-be-copied">jetcbt.jp</span></div>
    </div>
    <div class="mb-3">
      <div><span class="code-label">会場識別コード: </span><span v-if="loading" class="value-to-be-copied">（読み込み中）</span><span v-else class="value-to-be-copied">{{authCode}}</span></div>
    </div>
    <div class="text-center buttons">
      <button v-button:close @click="close"/>
    </div>
  </section>
</template>

<script>
import { Service } from "../_resource";

export default {
  data() {
    return {
      authCode: null,
      loading: true
    }
  },
  props: [
      "siteId",
      "examTermId"
  ],
  mounted: function () {
    Service.getAuthCode(this.siteId, this.examTermId).then((res) => {
      this.authCode = res.authCode;
      if (this.authCode == null) {
        this.authCode = "エラーが発生しました";
      }
    }).finally(() => {
      this.loading = false;
    });
  },
  methods: {
    close() {
      this.$emit('close', false);
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 3rem;
}
div.item {
  margin: 1rem 0;
}
.code-label{
  display: inline-block;
  width: 220px;
  text-align: right;
  padding-right: 10px;
  font-size: 2rem;
}
.value-to-be-copied {
  display: inline-block;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 2em;
}
</style>
