<template>
  <section>
    <form v-form class="form-inline examinee-form" v-if="examinee">
      <div class="row">
        <div class="table-responsive" v-if="edit">
          <correct-legend :corrected="false"/>
          <table class="table table-bordered edit" v-table:info v-cols="[28, 72]">
            <tbody>
            <tr>
              <th>受検者名</th>
              <td :class="{wrong: examinee.nameWrong, corrected: editingExaminee.nameCorrected}">
                {{examinee.familyName}} {{examinee.givenName}}
                <label class="correct"><input type="checkbox" v-model="editingExaminee.nameCorrected">修正する</label>
                <div v-if="editingExaminee.nameCorrected" class="correction">
                  <input-text class="name" v-model="editingExaminee.familyName" />
                  <input-text class="name" v-model="editingExaminee.givenName" />
                </div>
              </td>
            </tr>
            <tr>
              <th>受検者名ローマ字</th>
              <td :class="{wrong: examinee.nameEnWrong, corrected: editingExaminee.nameEnCorrected}">
                {{examinee.givenNameEn}} {{examinee.familyNameEn}}
                <label class="correct"><input type="checkbox" v-model="editingExaminee.nameEnCorrected">修正する</label>
                <div v-if="editingExaminee.nameEnCorrected" class="correction">
                  <input-text class="name" v-model="editingExaminee.givenNameEn" />
                  <input-text class="name" v-model="editingExaminee.familyNameEn" />
                </div>
              </td>
            </tr>
            <tr>
              <th>性別</th>
              <td :class="{wrong: examinee.sexWrong, corrected: editingExaminee.sexCorrected}">
                {{examinee.sex.name}}
                <label class="correct"><input type="checkbox" v-model="editingExaminee.sexCorrected">修正する</label>
                <div v-if="editingExaminee.sexCorrected" class="correction">
                  <input-radios v-model="editingExaminee.sexId" :options="sexOptions"/>
                </div>
              </td>
            </tr>
            <tr>
              <th>生年月日</th>
              <td :class="{wrong: examinee.dateOfBirthWrong, corrected: editingExaminee.dateOfBirthCorrected}">
                {{examinee.dateOfBirth | date('YYYY/MM/DD')}}
                <label class="correct"><input type="checkbox" v-model="editingExaminee.dateOfBirthCorrected">修正する</label>
                <div v-if="editingExaminee.dateOfBirthCorrected" class="correction">
                  <input-date v-model="editingExaminee.dateOfBirth"/>
                </div>
              </td>
            </tr>
            <tr>
              <th>学年</th>
              <td :class="{wrong: examinee.schoolGradeWrong, corrected: editingExaminee.schoolGradeCorrected}">
                {{examinee.schoolGrade.name}}
                <label class="correct"><input type="checkbox" v-model="editingExaminee.schoolGradeCorrected">修正する</label>
                <div v-if="editingExaminee.schoolGradeCorrected" class="correction">
                  <input-select v-model="editingExaminee.schoolGradeId" :options="schoolGradeOptions"/>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="hint">
            修正する項目の「修正する」にチェックを入れて、修正内容を入力してください。
          </div>
        </div>
        <div class="table-responsive" v-if="!edit">
          <table class="table table-bordered" v-table:info v-cols="[28, 72]">
            <tbody>
            <tr>
              <th>受検番号</th>
              <td>{{examinee.examineeNumber}}</td>
            </tr>
            <tr>
              <th>受検級</th>
              <td>{{examinee.examGradeName}}</td>
            </tr>
            <tr>
              <th>試験開始日時</th>
              <td>{{examinee.startTime | timestamp}}</td>
            </tr>
            <tr>
              <th>試験終了日時</th>
              <td>{{examinee.endTime | timestamp}}</td>
            </tr>
            </tbody>
          </table>

          <div class="text-center buttons mb-2">
            <span><button v-if="(examinee.startTime || examinee.endTime) && !examTerm.ended" v-button:delete @click="reset">受検状態をリセットする</button></span>
          </div>
          <correct-legend/>
          <table class="table table-bordered" v-table:info v-cols="[28, 72]">
            <tbody>
            <tr>
              <th>受検者名</th>
              <td :class="{wrong: examinee.nameWrong, corrected: examinee.nameCorrected}">
                <div>
                  <span v-if="correctedFamilyName">
                    {{correctedFamilyName}}
                  </span>
                  <span v-else>
                  {{examinee.familyName}}
                  </span>
                  <span v-if="correctedGivenName">
                    {{correctedGivenName}}
                  </span>
                  <span v-else>
                    {{examinee.givenName}}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th>受検者名ローマ字</th>
              <td :class="{wrong: examinee.nameEnWrong, corrected: examinee.nameEnCorrected}">
                <div>
                  <span v-if="correctedGivenNameEn">
                    {{correctedGivenNameEn}}
                  </span>
                  <span v-else>
                  {{examinee.givenNameEn}}
                  </span>
                  <span v-if="correctedFamilyNameEn">
                    {{correctedFamilyNameEn}}
                  </span>
                  <span v-else>
                    {{examinee.familyNameEn}}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th>性別</th>
              <td :class="{wrong: examinee.sexWrong, corrected: examinee.sexCorrected}">
                <div>{{correctedSex.name}}</div>
              </td>
            </tr>
            <tr>
              <th>生年月日</th>
              <td :class="{wrong: examinee.dateOfBirthWrong, corrected: examinee.dateOfBirthCorrected}">
                <div>{{correctedDateOfBirth | date('YYYY/MM/DD')}}</div>
              </td>
            </tr>
            <tr>
              <th>学年</th>
              <td :class="{wrong: examinee.schoolGradeWrong, corrected: examinee.schoolGradeCorrected}">
                <div>{{correctedSchoolGrade.name}}</div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center buttons">
          <span><button v-if="edit" v-button:cancel @click="cancel"/></span>
          <span><button v-if="!edit" v-button:close @click="close"/></span>
          <span><button v-if="edit" v-button:primary @click="save">修正内容を保存する</button></span>
          <span><button v-if="!edit && !examTerm.correctionDeadlinePassed" v-button:primary @click="toEdit">受検者情報を修正する</button></span>
          <span><button v-if="!edit && examinee.locked" v-button:primary @click="unlockAccount">アカウントロック解除</button></span>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import {Service} from "../_resource";
import {announce} from "@/calico-vue/service";
import InputRadios from "@/calico-vue/form/components/InputRadios";
import InputSelect from "@/calico-vue/form/components/InputSelect";
import CorrectLegend from "@/app/exam/official/CorrectLegend";

export default {
  components: {CorrectLegend, InputSelect, InputRadios},
  data() {
    return {
      examinee: null,
      editingExaminee: null,
      examGrades: [],
      examGradeCode: null,
      edit: false,
    };
  },
  computed: {
    sexOptions() {
      return [
        {id: 'MALE', name: '男'},
        {id: 'FEMALE', name: '女'},
      ];
    },
    schoolGradeOptions() {
      return Object.values(this.$ExtEnum.SchoolGrade).slice(1);
    },
    correctedFamilyName() {
      return this.examinee.nameCorrected ? this.examinee.correctedFamilyName : this.examinee.familyName;
    },
    correctedGivenName() {
      return this.examinee.nameCorrected ? this.examinee.correctedGivenName : this.examinee.givenName;
    },
    correctedFamilyNameEn() {
      return this.examinee.nameEnCorrected ? this.examinee.correctedFamilyNameEn : this.examinee.familyNameEn;
    },
    correctedGivenNameEn() {
      return this.examinee.nameEnCorrected ? this.examinee.correctedGivenNameEn : this.examinee.givenNameEn;
    },
    correctedSex() {
      return this.examinee.sexCorrected ? this.examinee.correctedSex : this.examinee.sex;
    },
    correctedDateOfBirth() {
      return this.examinee.dateOfBirthCorrected ? this.examinee.correctedDateOfBirth : this.examinee.dateOfBirth;
    },
    correctedSchoolGrade() {
      return this.examinee.schoolGradeCorrected ? this.examinee.correctedSchoolGrade : this.examinee.schoolGrade;
    },
    isChangedName() {
      if (!this.editingExaminee.nameCorrected) return false;
      if (!this.editingExaminee.familyName && !this.examinee.givenName) return false;
      if (this.editingExaminee.familyName && this.editingExaminee.familyName !== this.examinee.familyName) return true
      return this.editingExaminee.givenName && this.editingExaminee.givenName !== this.examinee.givenName;
    },
    isChangedNameEn() {
      if (!this.editingExaminee.nameEnCorrected) return false;
      if (!this.editingExaminee.familyNameEn && !this.editingExaminee.givenNameEn) return false;
      if (this.editingExaminee.familyNameEn && this.editingExaminee.familyNameEn !== this.examinee.familyNameEn) return true
      return this.editingExaminee.givenNameEn && this.editingExaminee.givenNameEn !== this.examinee.givenNameEn;
    },
    isChangedSex() {
      if (!this.editingExaminee.sexCorrected) return false;
      return this.editingExaminee.sexId && this.editingExaminee.sexId != this.examinee.sex.id;
    },
    isChangedDateOfBirth() {
      if (!this.editingExaminee.dateOfBirthCorrected) return false;
      return this.editingExaminee.dateOfBirth && this.editingExaminee.dateOfBirth != this.examinee.dateOfBirth;
    },
    isChangedSchoolGrade() {
      if (!this.editingExaminee.schoolGradeCorrected) return false;
      return this.editingExaminee.schoolGradeId && this.editingExaminee.schoolGradeId != this.examinee.schoolGrade.id;
    }
  },
  props: [
    "siteId",
    "examineeId",
    "examTerm"
  ],
  mounted() {
    Service.getExaminee(this.siteId, this.examineeId).then(examinee => {
      this.setExaminee(examinee);
    });
  },
  methods: {
    setExaminee(examinee) {
      this.examinee = examinee;
    },
    close() {
      this.$emit('close', true);
    },
    save() {
      const correction = {
        familyName: this.isChangedName ? this.editingExaminee.familyName : null,
        givenName: this.isChangedName ? this.editingExaminee.givenName : null,
        familyNameEn: this.isChangedNameEn ? this.editingExaminee.familyNameEn : null,
        givenNameEn: this.isChangedNameEn ? this.editingExaminee.givenNameEn : null,
        sex: this.isChangedSex ? this.editingExaminee.sexId: null,
        schoolGrade: this.isChangedSchoolGrade ? this.editingExaminee.schoolGradeId : null,
        dateOfBirth: this.isChangedDateOfBirth ? this.editingExaminee.dateOfBirth : null,
      };
      Service.updateExamniee(this.siteId, this.examineeId, correction).then(() => {
        Service.getExaminee(this.siteId, this.examineeId).then(examinee => {
          this.setExaminee(examinee)
          announce.saved();
          this.edit = false;
        });
      }).catch(e => this.error = e);
    },
    reset() {
      if (!confirm('受検状態をリセットしてもよろしいですか？')) return;
      Service.resetExam(this.siteId, this.examineeId).then(() => {
        announce.saved({title: '受検状態をリセットしました。'});
        this.$emit('close', true);
      });
    },
    toEdit() {
      this.edit = true;
      this.editingExaminee = {
        nameCorrected: this.examinee.nameCorrected,
        nameEnCorrected: this.examinee.nameEnCorrected,
        sexCorrected: this.examinee.sexCorrected,
        dateOfBirthCorrected: this.examinee.dateOfBirthCorrected,
        schoolGradeCorrected: this.examinee.schoolGradeCorrected,
        familyName: this.examinee.correctedFamilyName,
        givenName: this.examinee.correctedGivenName,
        familyNameEn: this.examinee.correctedFamilyNameEn,
        givenNameEn: this.examinee.correctedGivenNameEn,
        sexId: this.examinee.correctedSex ? this.examinee.correctedSex.id : null,
        dateOfBirth: this.examinee.correctedDateOfBirth,
        schoolGradeId: this.examinee.correctedSchoolGrade ? this.examinee.correctedSchoolGrade.id : null,
      }
    },
    cancel() {
      this.editingExaminee = null;
      this.edit = false;
    },
    unlockAccount() {
      Service.unlockAccount(this.siteId, this.examineeId).then(() => {
        announce.saved({title: 'アカウントロックを解除しました。'});
        this.$emit('close', true);
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.correction {
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: #d0ffd0;
}
label.correct {
  display: inline-block;
  font-size: 0.9em;
  font-weight: normal;
  background-color: #f0f0f0;
  border-radius: 0.5rem;
  padding: 0.2rem 0.5rem;
  input {
    margin-right: 0.5rem;
  }
}
.edit td {
  &.wrong {
    background-color: inherit;
  }
  &.corrected {
    background-color: inherit;
    &:after {
      content: none;
    }
  }
}
td {
  position: relative;
  &.wrong {
    background-color: #ffddaa;
    &:before {
      content: 'warning';
      font-family: "Material Icons";
      position: absolute;
      top: 0;
      right: 0;
      color: #ffa500;
    }
    &.corrected:before {
      right: 1em;
    }
  }
  &.corrected {
    background-color: #d0ffd0;
    &:after {
      content: 'check';
      font-family: "Material Icons";
      position: absolute;
      top: 0;
      right: 0;
      color: #008800;
    }
  }
}
</style>

<style lang="scss">
.examinee-form .name {
  input {
    width: 45% !important;
  }
  + .name {
    margin-left: 5px;
  }
}
.buttons button {
  margin-left: 1rem;
}
</style>
