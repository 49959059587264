<template>
  <div class="correct-legend">
    <span v-if="wrong" class="wrong">…間違い申告あり</span>
    <span v-if="corrected" class="corrected">…修正済</span>
  </div>
</template>

<script>
export default {
  props: {
    wrong: {
      type: Boolean,
      default: true
    },
    corrected: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.correct-legend {
  text-align: right;
  color: #666;
  span {
    margin-left: 1em;
    &.wrong:before {
      content: 'warning';
      font-family: "Material Icons";
      color: orange;
    }
    &.corrected:before {
      content: 'check';
      font-family: "Material Icons";
      color: green;
    }
  }
}
</style>
